import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { LinkIn } from "../../components/Link";

import logo from '../../assets/images/base/logo.png'
import star from '../../assets/images/base/star.png'

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    width: '130px',
    margin: '20px 0px 0px 0px',
  },
  star: {
    margin: '15px 0px 0px 0px',
    width: '50px',
  },
}));

export default function Default(props) {
  const classes = useStyles();
  const {
    handleDrawerToggle, mobileOpen, loc, setIntro
  } = props

  return (
    <AppBar position="static" 
      className={classes.appBar} 
      elevation={0} 
      color="transparent"
      >
      <Toolbar>
        {!mobileOpen && (
          <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item>
          {loc === '/' ? (
            <img
              className={classes.logo} 
              src={logo} alt="DEMO logo"
              onClick={() => setIntro(true)}
            />
          ) : (
            <LinkIn to='/'>
              <img
                className={classes.logo} 
                src={logo} alt="DEMO logo"
              />
            </LinkIn>
          )}
          </Grid>
          <Grid item>
        <img
        className={classes.star}
        onClick={() => handleDrawerToggle()}
        src={star} alt="Menu"
      />
        </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
}


