import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'

import { makeStyles } from '@material-ui/core/styles'

import logo from '../../assets/images/base/logo.png'
import star from '../../assets/images/base/star.png'
import starGreen from '../../assets/images/base/star-green.png'
import close from '../../assets/images/base/close.png'

import { LinkIn } from '../../components/Link'

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '100%',
    backgroundColor: "transparent"
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  star: {
    marginRight: '10px',
    width: '13px',
    '&::selection': {
      backgroundColor: '#000'
    }
  },
  listP: {
    display: 'inline',
    '&::selection': {
      backgroundColor: '#000'
    },
    letterSpacing: '2px',
    lineHeight: '23pt'
  },
  logoBar: {
    width: '130px',
    margin: '15px 0px 0px 0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoBarMob: {
    width: '130px',
    margin: '15px 0px 0px 22px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButtonMob: {
    margin: '15px 15px 15px 15px',
    width: '50px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  listItemMob: {
    display: 'block',
    textAlign: 'left',
    fontSize: '24px',
    color: '#fff',
    margin: '10px 10px 10px 15px',
    fontFamily: 'FreightBigBook',
    "&:hover": {
      textDecoration: "none"
    },
    '&::selection': {
      backgroundColor: '#000'
    }
  },
}));

function ResponsiveDrawer({mobileOpen, handleDrawerToggle, loc, page}) {
  const classes = useStyles();

  return (
    <Drawer
    variant="temporary"
    open={mobileOpen}
    onClose={() => handleDrawerToggle()}
    classes={{
      paper: classes.drawerPaper,
    }}
    ModalProps={{
      keepMounted: true,
    }}
    elevation={0}
  >
    <div className={classes.menu}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          {loc === '/' ? (
          <img
            className={classes.logoBarMob} 
            src={logo} alt="DEMO logo"
            onClick={() => handleDrawerToggle()}
          />
          ) : (
          <LinkIn to='/'>
            <img
              className={classes.logoBarMob} 
              src={logo} alt="DEMO logo"
            />
          </LinkIn>
          )}
        </Grid>
        <Grid item>
          <img
            className={classes.menuButtonMob}
            onClick={() => handleDrawerToggle()}
            src={close} alt="Menu"
          />
        </Grid>
      </Grid>
        
      <List>
        {[
          {t:'about',u:'/about'},
          {t:'archive',u:'/archive'},
          {t:'upcoming',u:'/upcoming'},
        ].map((obj, i) => (
          <LinkIn key={i} to={obj.u} className={classes.listItemMob}>
            <span>
            <img
              className={classes.star}
              src={page === obj.t ? starGreen : star} alt={obj.t}
            /> 
            <p className={classes.listP}
              style={{color: page === obj.t ? '#00fe00' : '#fff'}}
            >{obj.t}</p>
            </span>
          </LinkIn>
        ))}
      </List>
    </div>

  </Drawer>
  )
}

export default ResponsiveDrawer;
