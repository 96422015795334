import React, {useContext} from 'react'
import { useLocation } from "@reach/router"

import HeaderWeb from './HeaderWeb'
import HeaderMobile from './HeaderMobile'
import DrawerMobile from './DrawerMobile'

import {IntroContext} from '../../context'

function ResponsiveDrawer(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const {setIntro} = useContext(IntroContext)

  return (
    <>
      <HeaderWeb
        page={props.page}
        loc={location.pathname}
        setIntro={setIntro}
      />

      <HeaderMobile
        page={props.page}
        mobileOpen={mobileOpen}
        loc={location.pathname}
        setIntro={setIntro}
        handleDrawerToggle={handleDrawerToggle}
      />

      <DrawerMobile
        handleDrawerToggle={handleDrawerToggle}
        loc={location.pathname}
        page={props.page}
        mobileOpen={mobileOpen}
      />

      <main>
        {!mobileOpen && props.children}
      </main>
    </>
  );
}

export default ResponsiveDrawer;
