import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { LinkIn } from "../../components/Link";

import logo from '../../assets/images/base/logo.png'
import star from '../../assets/images/base/star.png'

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: '100px',
    flexWrap: 'wrap',
  },
  logo: {
    height: '68px',
    margin: '20px 0px 0px 20px',
  },
  tagline: {
    margin: 'auto 0px 0px 0px',
  },
  star: {
    height: '20px',
    margin: '0px 10px 0px 10px',
  },
  title: {
    display: 'inline',
  },
  grow: {
    flexGrow: 1,
  },
  nav: {
    margin: '10px 20px auto 0px',
  },
  link: {
    margin: theme.spacing(1),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuItem: {
    display: 'inline'
  }

}));

export default function Default({page, setIntro, loc}) {
  const classes = useStyles();

  return (
      <AppBar position="static" color="transparent" elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}
        disableGutters
        >

        {loc === '/' ? (
          <img
            className={classes.logo}
            src={logo} alt="DEMO"
            onClick={() => setIntro(true)}
          />
        ) : (
          <LinkIn to='/'
          style={{lineHeight: 0}}
          >
          <img
            className={classes.logo} 
            src={logo} alt="DEMO logo"
          />
          </LinkIn>
        )}
          <div className={classes.tagline}>
          <img
            className={classes.star} 
            src={star} alt="DEMO logo"
          />
          <Typography 
            className={classes.title}
            color="primary"
            component='h1'
            variant="h6"
          >
            Moving Image Experimental Politics
          </Typography>
          </div>
          <div 
            className={classes.grow}>
          </div>
          <nav className={classes.nav}>
          {[
            {t:'About',u:'/about', p: 'about'},
            {t:'Archive',u:'/archive', p: 'archive'},
            {t:'Upcoming',u:'/upcoming', p: 'upcoming'},
          ].map((obj, index) => (
            <Typography 
            variant="subtitle1"
            className={classes.menuItem}
          >
            <LinkIn key={index} to={obj.u} 
            className={classes.link}
              color={page === obj.p ? 'secondary' : 'primary' }
            >
              {obj.t}
            </LinkIn>
            </Typography>
          ))}
          </nav>
        </Toolbar>
      </AppBar>
  );
}


